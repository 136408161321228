<script setup lang="ts">
import { Blog } from '@/Pages/the-home/interfaces/Blog'
import { FeaturedCity } from '@/Components/featured-cities-slider/interfaces/FeaturedCity'
import DefaultLayout from '@/Layouts/DefaultLayout.vue'
import TheHeader from '@/Components/TheHeader.vue'
import TheHomeHero from '@/Pages/the-home/components/TheHomeHero.vue'
import TheHomeCompare from '@/Pages/the-home/components/TheHomeCompare.vue'
import TheHomeAdvantages from '@/Pages/the-home/components/TheHomeAdvantages.vue'
import TheHomeTestimonials from '@/Pages/the-home/components/TheHomeTestimonials.vue'
import TheHomeBlog from '@/Pages/the-home/components/TheHomeBlog.vue'
import TheHomeFeaturedCities from '@/Pages/the-home/components/TheHomeFeaturedCities.vue'
import { onMounted } from 'vue'
import { usePage } from '@inertiajs/vue3'
import { 
    toggleIsUserLogged
} from '@/Composables/authorization'


defineProps<{
    featuredCities: FeaturedCity[]
    blogs: Blog[]
}>()

const page = usePage()

onMounted(() => {
    if(page.props.user) {
        toggleIsUserLogged(true)
    } else {
        toggleIsUserLogged(false)
    }
})

</script>

<template>
    <DefaultLayout>
        <template #header>
            <TheHeader />
        </template>

        <TheHomeHero />

        <TheHomeCompare />

        <TheHomeAdvantages />

        <TheHomeFeaturedCities :cities="featuredCities" />

        <TheHomeTestimonials />

        <TheHomeBlog :blogs="blogs" />
    </DefaultLayout>
</template>

<style lang="scss" scoped>
:deep(.header-line) {
    height: 0;
}
</style>